import { useDefaultPagination } from '@/hooks/useDefaultPagination';
import { useEffect, useState } from 'react';
import { FaExternalLinkAlt, FaLinkedin } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { httpGetCompanyInfo, httpGetCompanyNotes } from '../api/CompaniesAPI';
import { httpCheckCompanyInList } from '../api/UserListsAPI';
import AddToListPopup from './AddToListPopup';
import { BaseModal } from './BaseModal/BaseModal';
import CompaniesTableMini from './CompaniesTableMini';
import FundingInfo from './FundingInfo';
import MarkdownText from './MarkdownText';
import Notes from './Notes';
import { Tag } from './Tag';
import { Typography } from './Typography';

const CompanyOverview = (props) => {
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState([]);
  const [alreadyInList, setAlreadyInList] = useState([]);

  const [isTagsModalOpen, setIsTagsModalOpen] = useState(false);

  const { id } = useParams();
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);

  useEffect(() => {
    loadCompanyInfo();
    loadCompanyNotes();
  }, [id]);

  const loadCompanyNotes = () => {
    httpGetCompanyNotes(id).then((response) => {
      setNotes(response.data);
    });
  };

  const loadCompanyInfo = () => {
    setLoading(true);

    httpGetCompanyInfo(id).then((response) => {
      setCompany(response.data);
      setLoading(false);

      httpCheckCompanyInList(id).then((response) => {
        setAlreadyInList(response.data);
      });
    });
  };

  const handleAddedToList = () => {
    httpCheckCompanyInList(id).then((response) => {
      setAlreadyInList(response.data);
    });
  };

  useDefaultPagination();

  return (
    <div className="w-full">
      {showAddToListPopup && (
        <AddToListPopup
          handleModalToggle={() => setShowAddToListPopup(false)}
          companiesIds={[id]}
          unavailableLists={alreadyInList}
          onSuccess={handleAddedToList}
        />
      )}
      {loading ? (
        <div className="flex h-[80vh] w-full items-center justify-center">
          <ClipLoader color="primary" size="40px" />
        </div>
      ) : (
        <div className="flex w-full flex-col items-center justify-center gap-10 lg:flex-row lg:items-start lg:justify-between lg:gap-0">
          {/*Left*/}
          <div className="min-h-[175px] w-full rounded-md bg-base-200 p-4 lg:w-[43%]">
            <h2 className="text-lg font-semibold">Business Description</h2>
            <div className="flex flex-col items-center justify-start gap-4 py-4 sm:flex-row">
              {/*LOGO*/}
              <img
                src={company.logoUrl}
                onError={(e) => (e.target.src = '/staticcompany.png')}
                alt="logo"
                className="rounded-full border"
                width="90px"
              />
              {/*Container(Company Name, Url, [+ Add]) */}
              <div className="flex flex-grow justify-between sm:justify-between">
                <div className="sm:w-9/10 w-full items-start justify-center space-y-3 lg:space-y-1">
                  <div className="justify-left flex flex-col items-center gap-3 text-base-content sm:flex-row sm:items-start">
                    <div className="justify-left flex break-words text-center text-xl font-bold sm:text-left">
                      {company.companyName}
                    </div>
                    <div className="flex items-center justify-center gap-3 text-base-content">
                      <a
                        href={company.linkedinUrl}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        rel="noreferrer"
                      >
                        <FaLinkedin size={20} />
                      </a>
                      <a
                        href={company.websiteUrl}
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        rel="noreferrer"
                      >
                        <FaExternalLinkAlt size={18} />
                      </a>
                    </div>
                  </div>
                  <div className="text-center text-sm text-base-content opacity-70 sm:text-left">
                    {company.industry}
                  </div>
                  <div className="text-center text-sm font-semibold text-base-content opacity-70 sm:text-left">
                    {company.employeeRange}
                  </div>
                </div>
              </div>
              <div className="w-1/10 flex flex-col items-center justify-center">
                <button
                  onClick={() => setShowAddToListPopup(true)}
                  className="btn mt-4 h-9 min-h-1 border-none bg-custom-gradient py-0 text-base-200 shadow-md transition-transform hover:scale-102 sm:mt-0"
                >
                  +&nbsp;&nbsp; Add
                </button>
                {alreadyInList.length > 0 && (
                  <div className="mt-2 text-center text-sm text-base-content opacity-70 sm:text-left">
                    Present in {alreadyInList.length} lists
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-[max-content,1fr] grid-rows-2 gap-x-4 gap-y-6 font-bold">
              <div className="flex flex-col gap-2">
                <div className="text-base-content opacity-70">Type</div>
                <div>{company.entityTypeL2 ?? '-'}</div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-base-content opacity-70">Tags</div>
                <div className="flex flex-wrap gap-1">
                  {company?.tags ? (
                    <>
                      {company.tags.slice(0, 4).map((tag) => (
                        <Tag key={tag} value={tag} />
                      ))}
                      {company.tags.length > 4 ? (
                        <button
                          onClick={() => setIsTagsModalOpen(true)}
                          className="rounded-full bg-grey-100 px-2 py-1 text-xs text-grey-700"
                        >
                          + more
                        </button>
                      ) : null}
                    </>
                  ) : (
                    <Tag value={'-'} />
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-base-content opacity-70">Founded</div>
                <div>{company.foundedIn ?? '-'}</div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-base-content opacity-70">Headquarter</div>
                <div className="">{company?.headquartersFullAddress ?? '-'}</div>
              </div>
            </div>

            {/*About the Company*/}
            <div className="w-full rounded-md px-4 pt-4 text-lg font-bold text-base-content opacity-70">
              About the company
            </div>

            <MarkdownText text={company.longDescription} />

            {/*Products and Services*/}
            <div className="w-full rounded-md px-4 pt-4 text-lg font-bold text-base-content opacity-70">
              Products and Services
            </div>
            <MarkdownText text={company.productsServices} />

            {/*Target Clients and industries*/}
            <div className="flex items-start justify-start">
              <div className="">
                <div className="w-full rounded-md px-4 pt-4 text-lg font-bold text-base-content opacity-70">
                  Target Clients
                </div>

                <MarkdownText text={company.targetClients} />
              </div>
              {/* {company.targetMarkets.length > 0 && (
                <div className="w-full font-bold py-4 px-4 text-md rounded-md">
                  <div className="text-base-content opacity-70">Target Markets </div>
                  <div className="">{company.targetMarkets} </div>
                </div>
              )} */}
            </div>
          </div>

          {/*Right*/}
          <div className="flex w-full flex-col gap-8 rounded-md lg:w-[55%]">
            {/*Similar Companies*/}
            <div className="min-h-[175px] rounded-md bg-base-200 p-4">
              <h2 className="mb-2 text-lg font-semibold">Similar Companies</h2>
              <CompaniesTableMini
                data={props?.response?.data?.data}
                totalRecords={props?.response?.data?.totalRecords}
              />
            </div>

            {/*Funding Info*/}
            {company?.totalRounds > 0 && (
              <div className="min-h-[175px] w-full rounded-md bg-base-200 p-4">
                <FundingInfo fundingInfo={company.foundingInfo} />
              </div>
            )}

            {/*Notes*/}
            <div className="min-h-[175px] w-full rounded-md bg-base-200 p-4">
              {company && <Notes companyId={id} oldNotes={notes} loadCompanyNotes={loadCompanyNotes} />}
            </div>
          </div>
        </div>
      )}
      {isTagsModalOpen ? (
        <BaseModal isOpen={isTagsModalOpen} onCloseHandler={() => setIsTagsModalOpen(false)}>
          <header className="flex items-center gap-5">
            <img
              src={company.logoUrl}
              onError={(e) => (e.target.src = '/staticcompany.png')}
              alt="logo"
              className="rounded-full border"
              width="90px"
            />
            <Typography arbitrary text={company.companyName} variant="xl/bold" className="break-words text-grey-900" />
          </header>
          <div className="flex flex-col gap-2">
            <div className="font-bold text-base-content opacity-70">Tags</div>
            <div className="flex flex-wrap gap-1">
              {company.tags.map((tag) => (
                <Tag key={`${tag}-modal`} value={tag} />
              ))}
            </div>
          </div>
        </BaseModal>
      ) : null}
    </div>
  );
};

export default CompanyOverview;
